<template>
  <v-app-bar id="app-bar" app color="white" elevate-on-scroll height="56" fixed style="z-index: 6">
    <v-btn text fab small @click="setDrawer(!drawer)">
      <v-icon v-if="!drawer"> mdi-view-quilt </v-icon>
      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-spacer />
    <div class="ms-3">{{ currentDateTime }}</div>

    <v-badge v-if="alertsCount" class="ms-5" color="green" :content="alertsCount">
      <router-link :to="alertsUrl">
        <v-icon color="black" size="20" class="cursor-pointer"> mdi-bell-ring-outline </v-icon>
      </router-link>
    </v-badge>

    <v-badge v-if="unseenMessageCount" class="ms-10" color="green" :content="unseenMessageCount">
      <router-link :to="ticketsUrl">
        <v-icon color="black" size="20" class="cursor-pointer">mdi-comment-text-multiple </v-icon>
      </router-link>
    </v-badge>

    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{on}">
        <v-btn v-on="on" text class="ms-3">
          <small>{{ userFullName }}</small>
          <v-icon color="black" size="20"> mdi-menu-down </v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <template v-for="item in menuItems">
          <v-list-item v-if="item.show" v-bind="item.attrs" :key="item.text" v-on="item.on">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.text" />
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import {now, toZone} from '@/lib/utils'

export default {
  name: 'AppBar',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      alertsCount: 0,
      unseenMessageCount: 0,
      currentDateTime: toZone(now()),
      interval: null
    }
  },
  created() {
    if (this.currentOrgId) {
      this.unseenOrgAlertCount()
    } else {
      this.unseenAlertCount()
    }
    this.unseenMessagesCount()
  },
  mounted() {
    this.interval = setInterval(() => {
      this.currentDateTime = toZone(now())
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters(['drawer', 'userFullName']),
    ...mapState(['currentOrgId']),
    ticketsUrl() {
      if(this.currentOrgId) {
        return '/org/tickets'
      } else {
        return '/tickets'
      }
    },
    alertsUrl() {
      if(this.currentOrgId) {
        return '/org/alerts?unseen=true'
      } else {
        return '/alerts?unseen=true'
      }
    },
    menuItems() {
      return [
        {
          show: true,
          text: this.$t('account.profile'),
          icon: 'mdi-account',
          attrs: {
            to: '/profile'
          }
        },
        {
          show: this.$hasPermission('org.organization.operation'),
          text: this.$t('organizations.editOrgInfo'),
          icon: 'mdi-office-building',
          attrs: {
            to: '/org-info'
          }
        },
        {
          show: true,
          text: this.$t('account.logout'),
          icon: 'mdi-logout',
          on: {
            click: () => {
              this.logout()
            }
          }
        }
      ]
    }
  },
  methods: {
    unseenAlertCount() {
      if (!this.$hasPermission('alert.operation')) {
        return
      }

      const filter = {seen: false, alertType: 'UV_DOSE'}
      return this.$api.alerts.count(filter).then((res) => {
        this.alertsCount = res
      })
    },
    unseenOrgAlertCount() {
      if (!this.$hasPermission('org.alert.operation')) {
        return
      }

      const filter = {seen: false, alertType: 'UV_DOSE'}
      return this.$api.org.alerts.count(filter).then((res) => {
        this.alertsCount = res
      })
    },
    unseenMessagesCount() {
      return this.$api.ticketMessages.unseenCount().then((res) => {
        this.unseenMessageCount = res
      })
    },
    logout() {
      return this.$api.account
        .logout()
        .then(() => {
          this.$logout()
          this.$showSuccess(this.$t('shared.youLogouted'))
        })
        .catch((error) => {
          this.$showError(error)
        })
    },
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
      $logout: 'LOGOUT'
    })
  }
}
</script>
